import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import TextFieldComponent from "./TextFieldComponent";
import SelectFieldComponent from "./SelectFieldComponent";
import { setupPayPartner, resolveBankAccount } from "../../api/admin/dashboard";
import closeIcon from "../../assets/icons/close-icon.svg";
import logo from "../../assets/logos/smart-deals-logo.svg";

const initialValues = {
	agency: "",
	partnerName: "",
	emailAddress: "",
	phoneNumber: "",
	bankName: "",
	bankCode: "",
	accountName: "",
	accountNumber: "",
	whitelist_ip: "",
	webhook: "",
	webhook_2: "",
};

const validationSchema = yup.object({
	agency: yup
		.string("Please enter agency")
		.min(3, "Agency should be minimum 3 characters long")
		.required("Agency is required"),
	partnerName: yup
		.string("Please enter partner name")
		.min(3, "Partner name should be minimum 3 characters long")
		.required("Partner name is required"),
	emailAddress: yup
		.string("Please enter email address")
		.min(3, "Email address should be minimum 3 characters long")
		.required("Email address is required"),
	phoneNumber: yup
		.string("Please enter phone number")
		.min(3, "Phone number should be minimum 3 characters long")
		.required("Phone number is required"),
	bankName: yup
		.string("Please enter bank name")
		.min(3, "Bank name should be minimum 3 characters long"),
	bankCode: yup
		.string("Please enter bank code")
		.min(3, "Bank code should be minimum 3 characters long"),
	accountName: yup
		.string("Please enter account name")
		.min(3, "Account name should be minimum 3 characters long"),
	accountNumber: yup
		.string("Please enter account number")
		.min(10, "Account number should be minimum 10 characters long")
		.max(10, "Account number should be maximum 10 characters long"),
	whitelist_ip: yup
		.string()
		.min(3, "White list should be minimum 3 characters long"),
	webhook: yup.string("Please enter webhook").url("Invalid URL format"),
	webhook_2: yup.string("Please enter webhook").url("Invalid URL format"),
});

export default function ResponsiveDialog({
	open,
	handleClose,
	title,
	subtitle,
	setCreatePartnerSuccessModalOpen,
	setCreateMessage,
	banksData,
}) {
	const [isResolvingAccountName, setIsResolvingAccountName] = useState(false);

	const queryClient = useQueryClient();
	const { user, role, region } = useSelector((state) => state.auth);
	// const [resolvedBankDetails, setResolvedBankDetails] = useState({});

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});

	const handleResolveBankDetails = async () => {
		try {
			setIsResolvingAccountName(true);
			const { values } = formik;

			const bankSelected = banksData.find(
				(bank) => bank.iCode === values.bankCode
			);
			const result = await resolveBankAccount({
				region: region?.region,
				accountNumber: values.accountNumber,
				bankCode: bankSelected?.iCode,
			});
			setIsResolvingAccountName(false);
			if (result?.data?.account_number && result?.data?.account_name) {
				toast.success(result?.message);
				// setResolvedBankDetails({
				// 	accountName: result?.data?.account_name,
				// 	accountNumber: result?.data?.account_number,
				// 	bank_id: result?.data?.bank_id,
				// 	bvn: values?.bvn,
				// });
				formik.setFieldValue("accountName", result?.data?.account_name);
			} else {
				toast.error(result?.message);
			}
		} catch (error) {
			setIsResolvingAccountName(false);
			if (error.response) {
				toast.error(error.response.data?.message);
			} else if (error.request) {
				toast.error("Network Error");
			} else {
				toast.error("Error", error.message);
			}
		}
	};

	const { mutate: addPayPartner } = useMutation(
		"add-pay-partner",
		setupPayPartner,
		{
			onSuccess: (dataSuccess) => {
				setCreateMessage(dataSuccess?.message);
				formik.setSubmitting(false);
				formik.resetForm();
				setCreatePartnerSuccessModalOpen(true);
				handleClose();
			},
			onError: (errors) => {
				formik.setSubmitting(false);
				if (errors.response) {
					toast.error(errors.response.data.message);
				} else if (errors.request) {
					toast.error("Network Error");
				} else {
					toast.error("Error", errors.message);
				}
			},
			onSettled: () => {
				queryClient.invalidateQueries("fetch-pay-partners");
			},
		}
	);

	const handleSubmit = (values) => {
		const payload = {
			role,
			username: user?.username,
			agency: values.agency,
			partnerName: values.partnerName,
			emailAddress: values.emailAddress,
			phoneNumber: values.phoneNumber,
			bankName: values.bankName,
			bankCode: values.bankCode,
			accountName: values.accountName,
			accountNumber: values.accountNumber,
			whitelist_ip: values.whitelist_ip,
			webhook: values.webhook,
			webhook_2: values.webhook_2,
		};

		if (values.whitelist_ip) {
			delete payload.whitelist_ip;
		}

		addPayPartner(payload);
	};

	useEffect(() => {
		if (
			!!formik.values.bankCode &&
			!!formik.values.accountNumber &&
			formik.values.accountNumber.length === 10
		) {
			handleResolveBankDetails();
		}
	}, [formik.values.accountNumber, formik.values.bankCode]);

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="responsive-dialog-title"
				sx={{
					borderRadius: "50px",
					"& .MuiDialog-paper": {
						borderRadius: "50px",
						maxWidth: "60rem",
					},
				}}
			>
				<DialogTitle id="responsive-dialog-title">
					<Box
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Button
							variant="text"
							sx={{ height: "3rem", width: "3rem" }}
							onClick={handleClose}
							endIcon={
								<img
									src={closeIcon}
									height="100%"
									width="100%"
									alt="close modal"
								/>
							}
						/>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Box sx={{ height: "5.25rem" }}>
							<img
								src={logo}
								height="100%"
								width="auto"
								alt="Cardinalstone logo"
							/>
						</Box>
						<Typography
							variant="h6"
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{title}
						</Typography>
						<Typography
							variant="h6"
							gutterBottom
							component="div"
							sx={{ color: "primary.main", fontWeight: 700 }}
						>
							{subtitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						paddingLeft: "2rem",
						paddingRight: "2rem",
						display: "flex",
						justifyContent: "center",
						mb: "3rem",
					}}
				>
					<Box
						sx={{
							width: { md: "40rem", xs: "100%" },
							p: { md: "0 2rem", xs: "0 1rem" },
							mt: "1rem",
						}}
					>
						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.agency}
								onChange={formik.handleChange}
								label="Agency"
								id="agency"
								name="agency"
								required
								error={formik.touched.agency && Boolean(formik.errors.agency)}
								helperText={formik.touched.agency && formik.errors.agency}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.partnerName}
								onChange={formik.handleChange}
								label="Partner name"
								id="partnerName"
								name="partnerName"
								required
								error={
									formik.touched.partnerName &&
									Boolean(formik.errors.partnerName)
								}
								helperText={
									formik.touched.partnerName && formik.errors.partnerName
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.emailAddress}
								onChange={formik.handleChange}
								label="Email address"
								id="emailAddress"
								name="emailAddress"
								required
								error={
									formik.touched.emailAddress &&
									Boolean(formik.errors.emailAddress)
								}
								helperText={
									formik.touched.emailAddress && formik.errors.emailAddress
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.phoneNumber}
								onChange={formik.handleChange}
								label="Phone Number"
								id="phoneNumber"
								name="phoneNumber"
								required
								error={
									formik.touched.phoneNumber &&
									Boolean(formik.errors.phoneNumber)
								}
								helperText={
									formik.touched.phoneNumber && formik.errors.phoneNumber
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<SelectFieldComponent
								width="100%"
								value={formik.values.bankName}
								values={banksData}
								onChange={(e) => {
									formik.handleChange(e);
									const selectedBank = banksData?.find(
										(item) => item?.bankName === e.target.value
									);
									formik.setFieldValue(`bankCode`, selectedBank.iCode || "");
								}}
								label="Bank Name"
								id="bankName"
								name="bankName"
								menuValue="bankName"
								displayValue="bankName"
								required
							/>
						</TextFieldContainer>

						{/* <TextFieldContainer>
							<TextFieldComponent
								value={formik.values.bankCode}
								onChange={formik.handleChange}
								label="Bank Code"
								id="bankCode"
								name="bankCode"
								required
								error={
									formik.touched.bankCode && Boolean(formik.errors.bankCode)
								}
								helperText={formik.touched.bankCode && formik.errors.bankCode}
							/>
						</TextFieldContainer> */}

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.accountNumber}
								onChange={(e) => {
									formik.handleChange(e);
								}}
								label="Account Number"
								id="accountNumber"
								name="accountNumber"
								maxLength={10}
								error={
									formik.touched.accountNumber &&
									Boolean(formik.errors.accountNumber)
								}
								helperText={
									isResolvingAccountName
										? "Resolving Account Name ..."
										: formik.values.accountName ||
										  (formik.touched.accountNumber &&
												formik.errors.accountNumber)
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.whitelist_ip}
								onChange={formik.handleChange}
								label="Whitelist IP"
								id="whitelist_ip"
								name="whitelist_ip"
								error={
									formik.touched.whitelist_ip &&
									Boolean(formik.errors.whitelist_ip)
								}
								helperText={
									formik.touched.whitelist_ip && formik.errors.whitelist_ip
								}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.webhook}
								onChange={formik.handleChange}
								label="Webhook"
								id="webhook"
								name="webhook"
								error={formik.touched.webhook && Boolean(formik.errors.webhook)}
								helperText={formik.touched.webhook && formik.errors.webhook}
							/>
						</TextFieldContainer>

						<TextFieldContainer>
							<TextFieldComponent
								value={formik.values.webhook_2}
								onChange={formik.handleChange}
								label="Webhook 2"
								id="webhook_2"
								name="webhook_2"
								error={
									formik.touched.webhook_2 && Boolean(formik.errors.webhook_2)
								}
								helperText={formik.touched.webhook_2 && formik.errors.webhook_2}
							/>
						</TextFieldContainer>

						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								color="primary"
								variant="contained"
								onClick={formik.handleSubmit}
								sx={{ p: "0.5rem 3rem" }}
								disabled={!formik.dirty || formik.isSubmitting}
							>
								{formik.isSubmitting ? (
									<CircularProgress size="1.5rem" />
								) : (
									"save"
								)}
							</Button>
						</Box>
					</Box>
				</DialogContent>
			</Dialog>
		</div>
	);
}

const TextFieldContainer = styled.div`
	margin-bottom: 1rem;
`;
