import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import GridViewIcon from "@mui/icons-material/GridView";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import FeaturedVideoOutlinedIcon from "@mui/icons-material/FeaturedVideoOutlined";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import MilitaryTechOutlinedIcon from "@mui/icons-material/MilitaryTechOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HandshakeIcon from "@mui/icons-material/Handshake";
import MailIcon from "@mui/icons-material/Mail";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import EventIcon from "@mui/icons-material/Event";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import ExtensionIcon from "@mui/icons-material/Extension";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import ChatIcon from "@mui/icons-material/Chat";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import PeopleIcon from "@mui/icons-material/People";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import RecommendIcon from "@mui/icons-material/Recommend";
import MemoryIcon from "@mui/icons-material/Memory";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import Tooltip from "@mui/material/Tooltip";

import DrawerItem from "../common/DrawerItem";
import FundPaystackBalance from "../common/FundPaystackBalance";
import ProcessStockRecommendation from "../common/ProcessStockRecommendation";
import { drawerWidth, ROLES, ACCOUNT_TYPES } from "../../utils/constants";

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	paddingBottom: "10rem",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const CollapibleContent = styled(Box)({
	marginLeft: ".5rem",
});

const AdminDrawer = ({ open, setOpen }) => {
	const [value, setValue] = useState(0);
	const [itemOnHover, setItemOnHover] = useState(null);
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const [openCollapse, setOpenCollapse] = useState(false);
	const [clubMembersCollapse, setClubMembersCollapse] = useState(false);
	const [partnersMembersCollapse, setPartnersMembersCollapse] = useState(false);
	const [dealsCollapse, setDealsCollapse] = useState(false);
	const [accountCollapse, setAccountCollapse] = useState(false);
	const [reportCollapse, setReportCollapse] = useState(false);
	const [logsCollapse, setLogsCollapse] = useState(false);
	const [requestsCollapse, setRequestsCollapse] = useState(false);
	const [eventsCollapse, setEventsCollapse] = useState(false);
	const [collabReportCollapse, setCollabReportCollapse] = useState(false);
	const [coopReportCollapse, setCoopReportCollapse] = useState(false);
	const [ngxReportCollapse, setNgxReportCollapse] = useState(false);
	const [virtualCardReportCollapse, setVirtualCardReportCollapse] =
		useState(false);

	const setSingleCollapse = (collapseType) => {
		setOpenCollapse(collapseType === "openCollapse");
		setClubMembersCollapse(collapseType === "clubMembersCollapse");
		setPartnersMembersCollapse(collapseType === "partnersMembersCollapse");
		setDealsCollapse(collapseType === "dealsCollapse");
		setAccountCollapse(collapseType === "accountCollapse");
		setReportCollapse(collapseType === "reportCollapse");
		setLogsCollapse(collapseType === "logsCollapse");
		setRequestsCollapse(collapseType === "requestsCollapse");
		setEventsCollapse(collapseType === "eventsCollapse");
		setCollabReportCollapse(collapseType === "collabReportCollapse");
		setCoopReportCollapse(collapseType === "coopReportCollapse");
		setNgxReportCollapse(collapseType === "ngxReportCollapse");
	};

	const theme = useTheme();
	const showDrawer = useMediaQuery(theme.breakpoints.up("md"));
	const { role, user } = useSelector((state) => state.auth);

	useEffect(() => {
		let isMounted = true;

		function handleResize() {
			setInnerWidth(window.innerWidth);
			if (innerWidth < 960 && isMounted && showDrawer) {
				setOpen(false);
			} else {
				setOpen(true);
			}
		}

		window.addEventListener("resize", handleResize);
		return () => {
			window.addEventListener("resize", handleResize);
			isMounted = false;
		};
	});

	useEffect(() => {
		let isMounted = true;

		if (innerWidth < 960 && isMounted && showDrawer) {
			setOpen(false);
		}

		return () => {
			isMounted = false;
		};
	}, [innerWidth]);

	useEffect(() => {
		let isMounted = true;

		if (isMounted && showDrawer) {
			if (window.location.pathname === "/dashboard" && value !== 0) {
				setValue(0);
			}
			if (window.location.pathname === "/partners" && value !== 1) {
				setValue(1);
			}
			if (window.location.pathname === "/suggested-partners" && value !== 16) {
				setValue(16);
			}
			if (window.location.pathname === "/members" && value !== 2) {
				setValue(2);
			}
			if (window.location.pathname === "/products" && value !== 3) {
				setValue(3);
			}
			if (window.location.pathname.match("/products/*") && value !== 3) {
				setValue(3);
			}
			if (window.location.pathname === "/live-deals" && value !== 4) {
				setValue(4);
			}
			if (window.location.pathname === "/deals-won" && value !== 5) {
				setValue(5);
			}
			if (window.location.pathname === "/checkout" && value !== 15) {
				setValue(15);
			}
			if (window.location.pathname === "/delivery" && value !== 17) {
				setValue(17);
			}
			if (window.location.pathname === "/failed-withdrawals" && value !== 19) {
				setValue(19);
			}
			if (window.location.pathname === "/wallet-report" && value !== 20) {
				setValue(20);
			}
			if (window.location.pathname === "/subscriptions" && value !== 21) {
				setValue(21);
			}
			if (window.location.pathname === "/banks" && value !== 22) {
				setValue(22);
			}
			if (window.location.pathname === "/verify-account" && value !== 23) {
				setValue(23);
			}
			if (window.location.pathname === "/flutter-log" && value !== 24) {
				setValue(24);
			}
			if (window.location.pathname === "/paystack-log" && value !== 25) {
				setValue(25);
			}
			if (window.location.pathname === "/providus-log" && value !== 26) {
				setValue(26);
			}
			if (window.location.pathname === "/create-admin" && value !== 27) {
				setValue(27);
			}
			if (window.location.pathname === "/discount-claimed" && value !== 6) {
				setValue(6);
			}
			if (window.location.pathname === "/adverts" && value !== 7) {
				setValue(7);
			}
			if (window.location.pathname === "/mailing-list" && value !== 18) {
				setValue(18);
			}
			if (
				window.location.pathname === "/settings/subscription-plans" &&
				value !== 8
			) {
				setValue(8);
			}
			if (window.location.pathname === "/settings/user-roles" && value !== 9) {
				setValue(9);
			}
			if (
				window.location.pathname === "/settings/admin-users" &&
				value !== 10
			) {
				setValue(10);
			}
			if (
				window.location.pathname === "/settings/change-password" &&
				value !== 11
			) {
				setValue(11);
			}
			if (window.location.pathname === "/notifications" && value !== 12) {
				setValue(12);
			}
			if (window.location.pathname === "/activity-log" && value !== 13) {
				setValue(13);
			}
			if (window.location.pathname === "/log-out" && value !== 14) {
				setValue(14);
			}
			if (window.location.pathname === "/submit-transfer" && value !== 28) {
				setValue(28);
			}
			if (window.location.pathname === "/complete-transfer" && value !== 29) {
				setValue(29);
			}
			if (window.location.pathname === "/wallet-balances" && value !== 30) {
				setValue(30);
			}
			if (window.location.pathname === "/events" && value !== 31) {
				setValue(31);
			}
			if (window.location.pathname === "/contact-us" && value !== 32) {
				setValue(32);
			}
			if (window.location.pathname === "/users" && value !== 33) {
				setValue(33);
			}
			if (window.location.pathname === "/executive-summary" && value !== 34) {
				setValue(34);
			}
			if (
				window.location.pathname === "/settings/update-app-version" &&
				value !== 35
			) {
				setValue(35);
			}
			if (
				window.location.pathname === "/settings/extend-subscription-validity" &&
				value !== 36
			) {
				setValue(36);
			}
			if (window.location.pathname === "/bill-payment" && value !== 37) {
				setValue(37);
			}
			if (window.location.pathname === "/push-notification" && value !== 38) {
				setValue(38);
			}
			if (window.location.pathname === "/payment-requests" && value !== 39) {
				setValue(39);
			}
			if (window.location.pathname === "/pay-with-smartdeals" && value !== 40) {
				setValue(40);
			}
			if (window.location.pathname === "/pending-discount" && value !== 41) {
				setValue(41);
			}
			if (
				window.location.pathname === "/delayed-transactions" &&
				value !== 42
			) {
				setValue(42);
			}
			if (window.location.pathname === "/support-chat" && value !== 43) {
				setValue(43);
			}
			if (window.location.pathname === "/pending-inflow" && value !== 44) {
				setValue(44);
			}
			if (window.location.pathname === "/user-referrals" && value !== 45) {
				setValue(45);
			}
			if (
				window.location.pathname === "/pending-providus-notifications" &&
				value !== 46
			) {
				setValue(46);
			}
			if (window.location.pathname === "/notice-board" && value !== 47) {
				setValue(47);
			}
			if (window.location.pathname === "/members-report" && value !== 48) {
				setValue(48);
			}
			if (
				window.location.pathname === "/collab-earnings-report" &&
				value !== 49
			) {
				setValue(49);
			}
			if (
				window.location.pathname === "/failing-baxi-service" &&
				value !== 50
			) {
				setValue(50);
			}
			if (window.location.pathname === "/coop-members-report" && value !== 51) {
				setValue(51);
			}
			if (
				window.location.pathname === "/coop-transactions-log" &&
				value !== 52
			) {
				setValue(52);
			}
			if (
				window.location.pathname === "/coop-transactions-summary" &&
				value !== 53
			) {
				setValue(53);
			}
			if (
				window.location.pathname === "/coop-wallet-balances" &&
				value !== 54
			) {
				setValue(54);
			}
			if (
				window.location.pathname === "/coop-earnings-report" &&
				value !== 55
			) {
				setValue(55);
			}
			if (window.location.pathname === "/scuml-report" && value !== 56) {
				setValue(56);
			}
			if (window.location.pathname === "/bvn-report" && value !== 57) {
				setValue(57);
			}
			if (window.location.pathname === "/fetch-interest" && value !== 58) {
				setValue(58);
			}
			if (window.location.pathname === "/ngx-monitor" && value !== 59) {
				setValue(59);
			}
			if (window.location.pathname === "/ngx-recommendations" && value !== 60) {
				setValue(60);
			}
			if (window.location.pathname === "/event-managers" && value !== 61) {
				setValue(61);
			}
			if (window.location.pathname === "/amc" && value !== 62) {
				setValue(62);
			}
			if (
				window.location.pathname === "/virtual-card-request" &&
				value !== 63
			) {
				setValue(63);
			}
			if (window.location.pathname === "/virtual-cards" && value !== 64) {
				setValue(64);
			}
			if (window.location.pathname === "/nin-report" && value !== 65) {
				setValue(65);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [value, showDrawer]);

	return (
		<Drawer
			variant="permanent"
			open={open}
			// sx={{
			// 	display: {
			// 		xs: "none",
			// 		md: "block",
			// 	},
			// }}
		>
			<Toolbar />
			<Divider />
			<List>
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Dashboard"
						open={open}
						Icon={GridViewIcon}
						linkTo="/dashboard"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={0}
					/>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Club Members">
							<ListItemButton
								onClick={() => setSingleCollapse("clubMembersCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<PeopleAltOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Club Members" />
								{clubMembersCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={clubMembersCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								<DrawerItem
									text="Members"
									open={open}
									Icon={PersonOutlineOutlinedIcon}
									linkTo="/members"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={2}
								/>
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Partners">
							<ListItemButton
								onClick={() => setSingleCollapse("partnersMembersCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<PersonPinOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Partners" />
								{partnersMembersCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={partnersMembersCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								<DrawerItem
									text="Partners"
									open={open}
									Icon={PersonPinOutlinedIcon}
									linkTo="/partners"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={1}
								/>
								<DrawerItem
									text="Partners Discounts"
									open={open}
									Icon={LocalOfferOutlinedIcon}
									linkTo="/partners-discounts"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={41}
								/>
								<DrawerItem
									text="Suggested Partners"
									open={open}
									Icon={HandshakeIcon}
									linkTo="/suggested-partners"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={16}
								/>
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* events */}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Events">
							<ListItemButton
								onClick={() => setSingleCollapse("eventsCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<EventIcon />
								</ListItemIcon>
								<ListItemText primary="Events" />
								{eventsCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={eventsCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								<DrawerItem
									text="Event Managers"
									open={open}
									Icon={PersonPinOutlinedIcon}
									linkTo="/event-managers"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={61}
								/>
								<DrawerItem
									text="Events"
									open={open}
									Icon={EventIcon}
									linkTo="/events"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={31}
								/>
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Products"
						open={open}
						Icon={ShoppingBasketOutlinedIcon}
						linkTo="/products"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={3}
					/>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Deals">
							<ListItemButton
								onClick={() => setSingleCollapse("dealsCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<ShoppingCartOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Deals" />
								{dealsCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={dealsCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{(role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Live Deals"
										open={open}
										Icon={PercentIcon}
										linkTo="/live-deals"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={4}
									/>
								)}
								<DrawerItem
									text="Deals Won"
									open={open}
									Icon={ShoppingCartOutlinedIcon}
									linkTo="/deals-won"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={5}
								/>
								<DrawerItem
									text="Checkout"
									open={open}
									Icon={ShoppingCartCheckoutIcon}
									linkTo="/checkout"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={15}
								/>
								{(role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Delivery"
										open={open}
										Icon={LocalShippingIcon}
										linkTo="/delivery"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={17}
									/>
								)}
								{(role === ROLES.CSU || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Fetch Interest"
										open={open}
										Icon={PercentIcon}
										linkTo="/fetch-interest"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={58}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Adverts"
						open={open}
						Icon={FeaturedVideoOutlinedIcon}
						linkTo="/adverts"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={7}
					/>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Query">
							<ListItemButton
								onClick={() => setSingleCollapse("accountCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<QueryStatsOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Query" />
								{accountCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={accountCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								<DrawerItem
									text="Banks"
									open={open}
									Icon={AccountBalanceIcon}
									linkTo="/banks"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={22}
								/>
								<DrawerItem
									text="Verify Account"
									open={open}
									Icon={AccountBalanceIcon}
									linkTo="/verify-account"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={23}
								/>
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Reports">
							<ListItemButton
								onClick={() => setSingleCollapse("reportCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<AssessmentOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Reports" />
								{reportCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={reportCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Pending Inflow"
										open={open}
										Icon={PaymentsIcon}
										linkTo="/pending-inflow"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={44}
									/>
								)}
								{(role === ROLES.CSU ||
									role === ROLES.FINCON ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Discount Claimed"
										open={open}
										Icon={LocalOfferOutlinedIcon}
										linkTo="/discount-claimed"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={6}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Failed Withdrawals"
										open={open}
										Icon={AccountBalanceIcon}
										linkTo="/failed-withdrawals"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={19}
									/>
								)}
								{(role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Mailing List"
										open={open}
										Icon={MailIcon}
										linkTo="/mailing-list"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={18}
									/>
								)}
								{(role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Contact Us"
										open={open}
										Icon={ContactMailIcon}
										linkTo="/contact-us"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={32}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Subscriptions"
										open={open}
										Icon={SubscriptionsOutlinedIcon}
										linkTo="/subscriptions"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={21}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Wallet"
										open={open}
										Icon={AccountBalanceWalletIcon}
										linkTo="/wallet-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={20}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Wallet Balances"
										open={open}
										Icon={AccountBalanceWalletIcon}
										linkTo="/wallet-balances"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={30}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Users"
										open={open}
										Icon={PeopleAltOutlinedIcon}
										linkTo="/users"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={33}
									/>
								)}
								{role === ROLES.EXECUTIVE && (
									<DrawerItem
										text="Executive Summary"
										open={open}
										Icon={AdminPanelSettingsIcon}
										linkTo="/executive-summary"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={34}
									/>
								)}
								{role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									(role === ROLES.EXECUTIVE && (
										<DrawerItem
											text="Bill Payment"
											open={open}
											Icon={CreditCardIcon}
											linkTo="/bill-payment"
											setValue={setValue}
											setItemOnHover={setItemOnHover}
											itemOnHover={itemOnHover}
											value={value}
											itemValue={37}
										/>
									))}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Payment Requests"
										open={open}
										Icon={PaymentsIcon}
										linkTo="/payment-requests"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={39}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Delayed Txns"
										open={open}
										Icon={PaymentsIcon}
										linkTo="/delayed-transactions"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={42}
									/>
								)}
								{(role === ROLES.CSU || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="User Referrals"
										open={open}
										Icon={PeopleAltIcon}
										linkTo="/user-referrals"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={45}
									/>
								)}
								{(role === ROLES.ADMIN ||
									role === ROLES.FINCON ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Pending Providus Notifications"
										open={open}
										Icon={AccountBalanceIcon}
										linkTo="/pending-providus-notifications"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={46}
									/>
								)}
								{(role === ROLES.ADMIN ||
									role === ROLES.CSU ||
									role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Baxi Service Status"
										open={open}
										Icon={AccountBalanceWalletIcon}
										linkTo="/failing-baxi-service"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={50}
									/>
								)}
								{(role === ROLES.FINCON || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="SCUML Report"
										open={open}
										Icon={AssessmentIcon}
										linkTo="/scuml-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={56}
									/>
								)}
								{(role === ROLES.FINCON ||
									role === ROLES.EXECUTIVE ||
									role === ROLES.CSU) && (
									<DrawerItem
										text="BVN Report"
										open={open}
										Icon={AccountBalanceIcon}
										linkTo="/bvn-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={57}
									/>
								)}
								{(role === ROLES.FINCON ||
									role === ROLES.EXECUTIVE ||
									role === ROLES.CSU) && (
									<DrawerItem
										text="NIN Report"
										open={open}
										Icon={PersonOutlineOutlinedIcon}
										linkTo="/nin-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={65}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* collab reports */}
				{(role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE ||
					user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COLLAB) && (
					<>
						<Tooltip title="Collab Reports">
							<ListItemButton
								onClick={() => setSingleCollapse("collabReportCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<AssessmentOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Collab Reports" />
								{collabReportCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={collabReportCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() ===
										ACCOUNT_TYPES.COLLAB) && (
									<DrawerItem
										text="Members Report"
										open={open}
										Icon={PeopleIcon}
										linkTo="/members-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={48}
									/>
								)}
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() ===
										ACCOUNT_TYPES.COLLAB) && (
									<DrawerItem
										text="Earnings Report"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/collab-earnings-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={49}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* coop reports */}
				{(role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE ||
					user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
					<>
						<Tooltip title="COOP Reports">
							<ListItemButton
								onClick={() => setSingleCollapse("coopReportCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<AssessmentOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="COOP Reports" />
								{coopReportCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={coopReportCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
									<DrawerItem
										text="Members Report"
										open={open}
										Icon={PeopleIcon}
										linkTo="/coop-members-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={51}
									/>
								)}
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
									<DrawerItem
										text="Transactions Log"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/coop-transactions-log"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={52}
									/>
								)}
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
									<DrawerItem
										text="Transactions Summary"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/coop-transactions-summary"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={53}
									/>
								)}
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
									<DrawerItem
										text="Wallet Balances"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/coop-wallet-balances"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={54}
									/>
								)}
								{(ROLES.EXECUTIVE ||
									role === ROLES.FINCON ||
									user?.accountType?.toLowerCase() === ACCOUNT_TYPES.COOP) && (
									<DrawerItem
										text="Earnings Report"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/coop-earnings-report"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={55}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* requests */}
				{(role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE ||
					role === ROLES.ADMIN) && (
					<>
						<Tooltip title="Requests">
							<ListItemButton
								onClick={() => setSingleCollapse("requestsCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<ArticleIcon />
								</ListItemIcon>
								<ListItemText primary="Requests" />
								{requestsCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={requestsCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{role === ROLES.FINCON && (
									<DrawerItem
										text="Initiate Transfer"
										open={open}
										Icon={AddIcon}
										linkTo="/submit-transfer"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={28}
									/>
								)}
								{role === ROLES.EXECUTIVE && (
									<DrawerItem
										text="Complete Transfer"
										open={open}
										Icon={AddTaskIcon}
										linkTo="/complete-transfer"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={29}
									/>
								)}
								{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="SmartDeals Pay"
										open={open}
										Icon={PaymentsIcon}
										linkTo="/pay-with-smartdeals"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={40}
									/>
								)}
								{role === ROLES.EXECUTIVE && (
									<FundPaystackBalance
										text="Fund Paystack"
										open={open}
										Icon={AccountBalanceIcon}
									/>
								)}
								{(role === ROLES.EXECUTIVE || role === ROLES.FINCON) && (
									<DrawerItem
										text="AMC"
										open={open}
										Icon={DynamicFormIcon}
										linkTo="/amc"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={62}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* logs */}
				{(role === ROLES.ADMIN ||
					role === ROLES.FINCON ||
					role === ROLES.EXECUTIVE) && (
					<>
						<Tooltip title="Logs">
							<ListItemButton
								onClick={() => setSingleCollapse("logsCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<LibraryBooksOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary="Logs" />
								{logsCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={logsCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
									<DrawerItem
										text="Activity Log"
										open={open}
										Icon={AccessTimeOutlinedIcon}
										linkTo="/activity-log"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={13}
									/>
								)}
								<DrawerItem
									text="Flutter Log"
									open={open}
									Icon={AccountBalanceIcon}
									linkTo="/flutter-log"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={24}
								/>
								<DrawerItem
									text="Paystack Log"
									open={open}
									Icon={AccountBalanceIcon}
									linkTo="/paystack-log"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={25}
								/>
								<DrawerItem
									text="Providus Log"
									open={open}
									Icon={AccountBalanceIcon}
									linkTo="/providus-log"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={26}
								/>
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* NGX */}
				{role === ROLES.EXECUTIVE && (
					<>
						<Tooltip title="NGX">
							<ListItemButton
								onClick={() => setNgxReportCollapse("ngxReportCollapse")}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<ShowChartIcon />
								</ListItemIcon>
								<ListItemText primary="NGX" />
								{ngxReportCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse in={ngxReportCollapse} timeout="auto" unmountOnExit>
							<CollapibleContent>
								{role === ROLES.EXECUTIVE && (
									<DrawerItem
										text="Monitor"
										open={open}
										Icon={MonitorHeartIcon}
										linkTo="/ngx-monitor"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={59}
									/>
								)}
								{role === ROLES.EXECUTIVE && (
									<DrawerItem
										text="Recommendations"
										open={open}
										Icon={RecommendIcon}
										linkTo="/ngx-recommendations"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={60}
									/>
								)}
								{role === ROLES.EXECUTIVE && (
									<ProcessStockRecommendation
										text="Process Recommendations"
										open={open}
										Icon={MemoryIcon}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{/* Virtual Card */}
				{(role === ROLES.EXECUTIVE || role === ROLES.FINCON) && (
					<>
						<Tooltip title="Virtual Card">
							<ListItemButton
								onClick={() =>
									setVirtualCardReportCollapse("virtualCardReportCollapse")
								}
								sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
							>
								<ListItemIcon>
									<CreditCardIcon />
								</ListItemIcon>
								<ListItemText primary="Virtual Card" />
								{virtualCardReportCollapse ? (
									<ArrowDropUpOutlinedIcon />
								) : (
									<ArrowDropDownOutlinedIcon />
								)}
							</ListItemButton>
						</Tooltip>
						<Collapse
							in={virtualCardReportCollapse}
							timeout="auto"
							unmountOnExit
						>
							<CollapibleContent>
								{(role === ROLES.EXECUTIVE || role === ROLES.FINCON) && (
									<DrawerItem
										text="Virtual Cards"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/virtual-cards"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={64}
									/>
								)}
								{(role === ROLES.EXECUTIVE || role === ROLES.FINCON) && (
									<DrawerItem
										text="Request"
										open={open}
										Icon={CreditCardIcon}
										linkTo="/virtual-card-request"
										setValue={setValue}
										setItemOnHover={setItemOnHover}
										itemOnHover={itemOnHover}
										value={value}
										itemValue={63}
									/>
								)}
							</CollapibleContent>
						</Collapse>
					</>
				)}
				{(role === ROLES.CSU || role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Support"
						open={open}
						Icon={ChatIcon}
						linkTo="/support-chat"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={43}
					/>
				)}
				{(role === ROLES.CSU || role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Notice Board"
						open={open}
						Icon={InsertPhotoIcon}
						linkTo="/notice-board"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={47}
					/>
				)}
				{(role === ROLES.ADMIN ||
					role === ROLES.CSU ||
					role === ROLES.EXECUTIVE) && (
					<DrawerItem
						text="Notifications"
						open={open}
						Icon={NotificationsNoneOutlinedIcon}
						linkTo="/notifications"
						setValue={setValue}
						setItemOnHover={setItemOnHover}
						itemOnHover={itemOnHover}
						value={value}
						itemValue={12}
					/>
				)}
				<>
					<Tooltip title="Settings">
						<ListItemButton
							onClick={() => setSingleCollapse("openCollapse")}
							sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
						>
							<ListItemIcon>
								<SettingsOutlinedIcon />
							</ListItemIcon>
							<ListItemText primary="Settings" />
							{openCollapse ? (
								<ArrowDropUpOutlinedIcon />
							) : (
								<ArrowDropDownOutlinedIcon />
							)}
						</ListItemButton>
					</Tooltip>
					<Collapse in={openCollapse} timeout="auto" unmountOnExit>
						<CollapibleContent>
							{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
								<DrawerItem
									text="Subscription Plans"
									open={open}
									linkTo="/settings/subscription-plans"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={8}
									Icon={SubscriptionsOutlinedIcon}
								/>
							)}
							{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
								<DrawerItem
									text="User Roles"
									open={open}
									linkTo="/settings/user-roles"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={9}
									Icon={MilitaryTechOutlinedIcon}
								/>
							)}
							{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
								<DrawerItem
									text="Admin Users"
									open={open}
									linkTo="/settings/admin-users"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={10}
									Icon={PeopleOutlineOutlinedIcon}
								/>
							)}
							{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
								<DrawerItem
									text="Create Admin"
									open={open}
									Icon={PersonAddAltIcon}
									linkTo="/create-admin"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={27}
								/>
							)}
							<DrawerItem
								text="Change Password"
								open={open}
								linkTo="/settings/change-password"
								setValue={setValue}
								setItemOnHover={setItemOnHover}
								itemOnHover={itemOnHover}
								value={value}
								itemValue={11}
								Icon={LockResetOutlinedIcon}
							/>
							{(role === ROLES.ADMIN || role === ROLES.EXECUTIVE) && (
								<DrawerItem
									text="Update App Version"
									open={open}
									linkTo="/settings/update-app-version"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={35}
									Icon={AppSettingsAltIcon}
								/>
							)}
							{role === ROLES.EXECUTIVE && (
								<DrawerItem
									text="Extend Subscription Validity"
									open={open}
									linkTo="/settings/extend-subscription-validity"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={36}
									Icon={ExtensionIcon}
								/>
							)}
							{role === ROLES.EXECUTIVE && (
								<DrawerItem
									text="Push Notifications"
									open={open}
									linkTo="/push-notification"
									setValue={setValue}
									setItemOnHover={setItemOnHover}
									itemOnHover={itemOnHover}
									value={value}
									itemValue={38}
									Icon={NotificationAddIcon}
								/>
							)}
						</CollapibleContent>
					</Collapse>
				</>
				<DrawerItem
					text="Log out"
					open={open}
					Icon={LogoutOutlinedIcon}
					linkTo="/logout"
					setValue={setValue}
					setItemOnHover={setItemOnHover}
					itemOnHover={itemOnHover}
					value={value}
					itemValue={14}
				/>
			</List>
		</Drawer>
	);
};

export default AdminDrawer;
